<template>
  <div class="item" v-if="rank === 0">
    <img alt="Без ранга" src="@/assets/icons/profile/no-rank.png" />
    Без ранга
  </div>
  <div class="item" v-else-if="rank === 1">
    <img alt="START" class="icon" src="@/assets/icons/profile/tstruck-b1.png" />
    START
  </div>
  <div class="item" v-else-if="rank === 2">
    <img alt="White" class="icon" src="@/assets/icons/profile/phantom-b1.png" />
    White
  </div>
  <div class="item" v-else-if="rank === 3">
    <img
      alt="Black"
      class="icon"
      src="@/assets/icons/profile/ncreature-b1.png"
    />
    Black
  </div>
  <div class="item" v-else-if="rank === 4">
    <img
      alt="Bronze"
      class="icon"
      src="@/assets/icons/profile/bronzec-b1.png"
    />
    Bronze
  </div>
  <div class="item" v-else-if="rank === 5">
    <img
      alt="Silver"
      class="icon"
      src="@/assets/icons/profile/silverc-b1.png"
    />
    Silver
  </div>
  <div class="item" v-else-if="rank === 6">
    <img alt="Gold" class="icon" src="@/assets/icons/profile/goldc-b1.png" />
    Gold
  </div>
  <div class="item" v-else-if="rank === 7">
    <img
      alt="Platinum"
      class="icon"
      src="@/assets/icons/profile/platinumc-b1.png"
    />
    Platinum
  </div>
  <div class="item" v-else-if="rank === 8">
    <img alt="Diamond" class="icon" src="@/assets/icons/profile/liked-b1.png" />
    Diamond
  </div>
  <div class="item" v-else-if="rank === 9">
    <img alt="VIP" class="icon" src="@/assets/icons/profile/rulerm-b1.png" />
    VIP
  </div>
  <div class="item" v-else-if="rank === 10">
    <img
      alt="Elite"
      class="icon"
      src="@/assets/icons/profile/upowered-b1.png"
    />
    Elite
  </div>
</template>

<script>
export default {
  props: {
    rank: Number,
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #b5b9d0;
  img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}
</style>
