<template>
  <div class="ranks-page">
    <div class="progress-bar">
      <div class="title">
        Ваш текущий уровень: {{ getRankName(user.rank.lvl) }}
      </div>
      <div class="description">
        Прогресс расчитывается исходя из вашей суммы ставок
      </div>

      <div class="progress">
        <div class="line" :style="{ width: getRanked.chance }">
          {{ getRanked.chance }}
        </div>
        <div class="finish">100%</div>
      </div>

      <div v-if="getRanked.lvl != user.rank.lvl" class="bottom">
        <Rank :rank="Number(user.rank.lvl)" />
        <Rank :rank="Number(getRanked.lvl)" />
      </div>
    </div>

    <div class="privilegies">
      <div class="title">Привелегии</div>
      <div class="flex">
        <Privilegy
          v-for="i in 10"
          :lvl="Number(user.rank.lvl)"
          :rank="i"
          :key="i"
          :active="activeRank"
          @setActive="setActiveRank"
        />
      </div>
    </div>

    <div class="cards">
      <div class="card">
        <img alt="coins" src="@/assets/icons/profile/cards-coins.png" />
        <div class="title">Ранговые промокоды</div>
        <div class="description">
          С каждым следующим рангом сумма промокода будет умножаться на
          коэффициент ниже
        </div>
        <div class="bottom" v-if="activeRank !== 0">
          <div class="item">
            <span>x{{ user.rank.promocode_lvls[activeRank] }}</span>
          </div>
        </div>
      </div>

      <div class="card block">
        <img alt="coins" src="@/assets/icons/profile/cards-chance.png" />
        <div class="title">CashBack</div>
        <div class="description">
          Процент % получаемого вами кешбека будет постоянно расти.
        </div>
        <div class="bottom">
          <div class="item">Скоро</div>
        </div>
      </div>

      <div class="card block">
        <img alt="coins" src="@/assets/icons/profile/cards-vager.png" />
        <div class="title">Турниры</div>
        <div class="description">
          С каждым следующим рангом награда умножается на коэффициент ниже
        </div>
        <div class="bottom">
          <div class="item">Скоро</div>
        </div>
      </div>

      <div class="card">
        <img alt="coins" src="@/assets/icons/profile/cards-manager.png" />
        <div class="title">Личный и VIP менеджер</div>
        <div class="description">
          Помогут вам решить ваши проблемы и получить повышенные бонусы.
        </div>
        <div class="bottom">
          <div class="item" v-if="activeRank < 6">Нет</div>
          <div class="item" v-else-if="activeRank <= 8">Личный менеджер</div>
          <div class="item" v-else>VIP менеджер</div>
        </div>
      </div>

      <div class="card">
        <img alt="coins" src="@/assets/icons/profile/cards-birthday.png" />
        <div class="title">День рождение</div>
        <div class="description">
          Это ваш праздник и мы с радостью украсим его денежным бонусом.
        </div>
        <div class="bottom" v-if="getBirthdray">
          <div class="item">
            НАГРАДА <span>{{ getBirthdray }}₽</span>
          </div>
        </div>
      </div>

      <div class="card">
        <img alt="coins" src="@/assets/icons/profile/cards-gift.png" />
        <div class="title">Награда за уровень</div>
        <div class="description">
          С каждым уровнем мы будем радовать вас подарками!
        </div>
        <div class="bottom" v-if="getLvl">
          <div class="item">
            НАГРАДА <span>{{ getLvl }}₽</span>
          </div>
        </div>
      </div>

      <!-- <div class="big-card">
        <div class="title">Высочайшая доходность для игрока</div>
        <div class="description">
          Наши бонусы абсолютно прозрачные. Все полученные бонусы вы сможете
          сразу выводить или снова пускать в игру.
          <div></div>
          Только чистый профит без дополнительных условий для игроков и других
          подводных камней.
        </div>
      </div>

      <div class="big-card">
        <div class="title">Эксклюзивные и гибкие бонусы</div>
        <div class="description">
          Мы ценим каждого игрока.
          <br />
          Ваш VIP-менеджер может поздравить вас с днем рождения и подарить вам
          новенький MacBook или подарить крупный бонус-код к новому году.
          <div></div>
          Все это и многое другое возможно в нашем VIP-клубе.
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Privilegy from "@/components/Profile/Ranks/Privilegy";
import { mapState } from "vuex";
import Rank from "@/components/Profile/Ranks/Rank";

export default {
  data() {
    return {
      activeRank: 0,
      rankNames: {
        0: "Без ранга",
        1: "START",
        2: "White",
        3: "Black",
        4: "Bronze",
        5: "Silver",
        6: "Gold",
        7: "Platinum",
        8: "Diamond",
        9: "VIP",
        10: "Elite",
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    getLvl() {
      return this.lvls[this.activeRank];
    },
    getBirthdray() {
      return this.birthdays[this.activeRank];
    },
    getRanked() {
      const rank = this.user.rank;
      const exp = Number(rank.exp);
      let lvl = Number(rank.lvl);

      const lvls = rank.lvls;
      if (lvls[lvl + 1]) lvl++;
      const points = lvls[lvl];

      let chance = (exp / points) * 100;
      if (chance > 100) chance = 100;
      else if (!chance) chance = 0;

      return {
        chance: chance.toFixed(1) + "%",
        lvl,
      };
    },
    lvls() {
      return this.user.rank.lvl_awards;
    },
    birthdays() {
      return this.user.rank.birthday_awards;
    },
  },
  created() {
    this.activeRank = Number(this.user.rank.lvl);
  },
  components: {
    Privilegy,
    Rank,
  },
  methods: {
    setActiveRank(rank) {
      this.activeRank = rank;
    },
    getRankName(lvl) {
      return this.rankNames[lvl] || "Без ранга";
    },
  },
};
</script>

<style lang="scss" scoped>
.ranks-page {
  .progress-bar {
    margin-bottom: 20px;
    .title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
      color: #000000;
    }

    .description {
      //   font-weight: 500;
      margin-bottom: 15px;
      font-size: 14px;
      color: #a3afc9;
    }

    .progress {
      background: #f7f7fb;
      border-radius: 4px;
      position: relative;
      height: 35px;
      margin-bottom: 15px;
      .line {
        background: #0085ff;
        border-radius: 4px;
        height: 35px;
        display: flex;
        width: 35%;
        min-width: 10%;
        max-width: 90%;
        align-items: center;
        justify-content: center;
        color: white;
      }
      .finish {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        color: #b5b9d0;
        font-size: 15px;
        line-height: 35px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
    }
  }

  .privilegies {
    .title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
      color: #000000;
    }
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card {
      width: calc(100% / 3 - 5px);
      // height: 255px;
      margin-bottom: 10px;
      background: #ffffff;
      border: 1px solid #e7e8ef;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;

      padding: 25px 15px;
      text-align: center;
      padding-bottom: 70px;
      img {
        display: block;
        margin: auto;
        margin-bottom: 8px;
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .description {
        font-size: 14px;
        color: #a3afc9;
        margin-bottom: 5px;
      }

      .bottom {
        .item {
          background: #ffffff;
          border: 1px solid rgba(228, 228, 241, 0.45);
          display: inline-block;
          border-radius: 6px;
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          color: #a3afc9;
        }

        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;

        span {
          color: black;
        }
      }
    }

    .big-card {
      width: calc(100% / 2 - 5px);
      height: 160px;
      padding: 22px;
      border: 1px solid #e7e8ef;
      border-radius: 8px;
      .title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .description {
        color: #a3afc9;
        font-size: 13px;
        div {
          margin-bottom: 10px;
        }
      }
    }

    @media (max-width: 768px) {
      .card {
        width: 100%;
      }
    }
  }
}
</style>
