<template>
  <div
    class="item"
    :class="{ active: active === rank }"
    @click="$emit('setActive', rank)"
  >
    <div class="badge" v-if="lvl === rank">
      <img alt="rank" src="@/assets/icons/profile/rank-check.svg" />
    </div>
    <span v-if="rank === 1">
      <img
        alt="START"
        class="icon"
        src="@/assets/icons/profile/tstruck-b1.png"
      />
      START
    </span>
    <span v-else-if="rank === 2">
      <img
        alt="White"
        class="icon"
        src="@/assets/icons/profile/phantom-b1.png"
      />
      White
    </span>
    <span v-else-if="rank === 3">
      <img
        alt="Black"
        class="icon"
        src="@/assets/icons/profile/ncreature-b1.png"
      />
      Black
    </span>
    <span v-else-if="rank === 4">
      <img
        alt="Bronze"
        class="icon"
        src="@/assets/icons/profile/bronzec-b1.png"
      />
      Bronze
    </span>
    <span v-else-if="rank === 5">
      <img
        alt="Silver"
        class="icon"
        src="@/assets/icons/profile/silverc-b1.png"
      />
      Silver
    </span>
    <span v-else-if="rank === 6">
      <img alt="Gold" class="icon" src="@/assets/icons/profile/goldc-b1.png" />
      Gold
    </span>
    <span v-else-if="rank === 7">
      <img
        alt="Platinum"
        class="icon"
        src="@/assets/icons/profile/platinumc-b1.png"
      />
      Platinum
    </span>
    <span v-else-if="rank === 8">
      <img
        alt="Diamond"
        class="icon"
        src="@/assets/icons/profile/liked-b1.png"
      />
      Diamond
    </span>
    <span v-else-if="rank === 9">
      <img alt="VIP" class="icon" src="@/assets/icons/profile/rulerm-b1.png" />
      VIP
    </span>
    <span v-else-if="rank === 10">
      <img
        alt="Elite"
        class="icon"
        src="@/assets/icons/profile/upowered-b1.png"
      />
      Elite
    </span>
  </div>
</template>

<script>
export default {
  props: {
    rank: Number,
    lvl: Number,
    active: Number,
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 81px;
  height: 100px;
  border-radius: 8px;
  padding: 18px 0;
  margin-bottom: 10px;
  font-size: 13px;
  position: relative;
  text-align: center;
  border: 1px solid #d5d8e8;
  color: #adadc6;
  &.active {
    border-color: #0085ff;
    pointer-events: none;
  }
  transition: 200ms ease;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .badge {
    width: 25px;
    height: 25px;
    background: #0085ff;
    box-shadow: 0px 15px 15px rgba(69, 99, 255, 0.25);
    border-radius: 6px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img.icon {
    display: block;
    margin: auto;
    margin-bottom: 5px;
  }
}
</style>
